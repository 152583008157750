<template>
  <div>
    <div>
      <NavBar></NavBar>
    </div>
    <div style="margin-left: 89px;">
      <div>
        <Header></Header>
      </div>
      <div>
        <v-toolbar dense :rounded="true" style="margin-botton:10px">
          <v-text-field
            hide-details
            single-line
            :placeholder="$t('orders.searchOrder')"
            v-model="dataToSearch"
          ></v-text-field>
          <v-btn icon @click="getOrder()">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-select
            style="padding-top: 20px;"
            :items="[
              'Nueva Solicitud',
              'En Proceso',
              'Completado para entrega',
              'Enviado con el delivery',
              'Completada',
              'Cancelada'
            ]"
            label="Estado"
            v-model="statusFilter"
            @click="gettingOrders()"
          ></v-select>
        </v-toolbar>
      </div>
      <div>
        <v-row
          align="center"
          no-gutters
          style="text-align: center;background-color: lightgrey; height: 50px;margin-top: 20px;"
        >
          <v-col cols="4">
            Ordenes
          </v-col>
          <v-col cols="4">
            Nota
          </v-col>
          <v-col cols="1">
            Fecha
          </v-col>
          <v-col cols="3">
            Total
          </v-col>
        </v-row>
      </div>
      <div>
        <v-responsive class="overflow-y-auto" max-height="77vh">
          <v-list>
            <v-list-item-group>
              <v-list-item
                style="background-color:#f3f3f3"
                v-for="(ords, index) in orders"
                :key="index"
              >
                <v-list-item-content>
                  <v-row align="center" no-gutters style="text-align: center;">
                    <v-col cols="4">
                      <v-row>
                        <v-col class="text-sm-left">
                          Codigo de orden: {{ ords.id }}
                        </v-col>
                        <v-col>
                          <v-btn
                            tile
                            color="success"
                            data-toggle="modal"
                            data-target="#orderModal"
                            @click="seeOrder(ords)"
                          >
                            <v-icon left>
                              fas fa-eye
                            </v-icon>
                            Detalle
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4" style="word-wrap: break-word;">
                      {{ ords.get("notes") }}
                    </v-col>
                    <v-col cols="1">
                      {{ ords.get("date") }}
                    </v-col>
                    <v-col cols="3">
                      $ {{ setFixed(ords.get("orderTotal")) }}
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-responsive>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      style="overflow:hidden !important"
      id="orderModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="orderModalTitle"
      aria-hidden="true"
    >
      <div
        class=" modal-position modal-dialog modal-dialog-centered"
        role="document"
      >
        <div>
          <div
            style="overflow-y:auto;border-radius:0"
            class="modal-content add-product-form modalContainer"
          >
            <div class="modal-header">
              <h5
                class="modal-title text-center"
                id="exampleModalLongTitle"
                style="font-family: 'Montserrat', sans-serif;"
              >
                {{ $t("orders.orderDetails") }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="h1 bold">&times;</span>
              </button>
            </div>
            <div class="modal-body specialBody" id="printOrder">
              <div class="text-center">
                <h4 class="textOrder2">
                  {{ $t("orders.orderCode") }}
                </h4>
                <barcode
                  :font-size="16"
                  :width="1"
                  :height="50"
                  v-bind:value="orderID"
                ></barcode>
              </div>
              <v-btn color="primary" v-print="'#printOrder'" fab small dark>
                <v-icon>fas fa-print</v-icon>
              </v-btn>
              <br />
              <p v-if="phone != null" class="detailsText4">{{ phone }}</p>
              <p class="detailsText5">{{ name }}</p>
              <p class="detailsText3">{{ username }}</p>
              <p v-if="phone == null" class="detailsText4">
                {{ $t("orders.notPhone") }}
              </p>
              <p v-if="driverName != ''" class="detailsText4">
                <b
                  >{{
                    languaje == "es"
                      ? "Delivery Asignado: "
                      : "Assigned Delivery: "
                  }}
                  {{ driverName }}</b
                >
              </p>
              <div class="row">
                <div class="col-5">
                  <p v-for="estado of estados" :key="estado.id">
                    <a
                      v-if="status == estado.id"
                      v-bind:class="{
                        'statusLabel newRequestState': status == 'R',
                        'statusLabel acceptedState': status == 'A',
                        'statusLabel onProccessState': status == 'IP',
                        'statusLabel deliveredState': status == 'F',
                        'statusLabel canceledState': status == 'C',
                        'statusLabel onWayState': status == 'E'
                      }"
                    >
                      {{ estado.descripcion }}
                    </a>
                  </p>
                </div>
                <div class="col-5">
                  <p
                    v-if="deliveryFee > 1 && status != 'F' && status != 'C'"
                    class="statusLabel deliveryState"
                  >
                    {{ $t("orders.delivery") }}
                  </p>
                  <p
                    v-if="deliveryFee < 1 && status != 'F' && status != 'C'"
                    class="statusLabel pickupState"
                  >
                    {{ $t("orders.pickup") }}
                  </p>
                </div>
              </div>
              <div class="totalCard bg-white">
                <div class="row justify-content-center">
                  <div class="col-1">
                    <p class="textDescription2"></p>
                  </div>
                  <div class="col-3">
                    <p class="textDescription2">
                      UPC
                    </p>
                  </div>
                  <div class="col-5">
                    <p class="textDescription2">
                      {{ $t("orders.item") }}
                    </p>
                  </div>
                  <div class="col-1">
                    <p class="textDescription2">
                      {{ $t("orders.qty") }}
                    </p>
                  </div>
                  <div class="col-2">
                    <p class="textDescription2">Total</p>
                  </div>

                  <div class="modalItemList">
                    <div
                      style="margin-left:0 !important"
                      class="row justify-content-center mt-3"
                      v-for="(item, index) in data"
                      :key="index"
                    >
                      <div class="col-1">
                        <div class="container" v-if="estado == 'IP'">
                          <input
                            type="checkbox"
                            @change="ProccessPayment"
                            class="mt-4"
                            :cheked="item.estado"
                            :id="index"
                          />
                        </div>
                      </div>
                      <div class="col-3">
                        <p class="textDescription2">
                          {{ item.upc }}
                        </p>
                      </div>
                      <div class="col-5">
                        <div class="productPicture">
                          <img
                            :src="item.prodImage"
                            v-bind:class="{
                              suprimirImg: item.estado == false,
                              productImg: item.estado == true
                            }"
                          />
                        </div>
                        <div class="productDescription">
                          <p
                            v-bind:class="{
                              suprimido: item.estado == false,
                              descriptionText: item.estado == true
                            }"
                          >
                            {{ item.prodName }}
                          </p>
                        </div>
                      </div>
                      <div class="col-1">
                        <p
                          v-bind:class="{
                            suprimido: item.estado == false,
                            descriptionText: item.estado == true
                          }"
                        >
                          {{ item.quantityNumber }}
                        </p>
                      </div>
                      <div class="col-2">
                        <p
                          v-bind:class="{
                            suprimido: item.estado == false,
                            descriptionText: item.estado == true
                          }"
                        >
                          $ {{ item.originalPrice }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style="fontsize: 14px; display:block;margin:auto;width:95%"
                    class="row"
                  >
                    <hr style="width:90%;" />
                    <div class="values col-md-12" style="margin-top: -10px;">
                      <div style="font-size:12px;">Sub Total:</div>
                      <div style="font-size:12px;">
                        ${{ setFixed(subtotal) }}
                      </div>
                    </div>
                    <div class="values col-md-12" style="margin-top: -10px;">
                      <div style="font-size:12px;">Ivu:</div>
                      <div style="font-size:12px;">${{ setFixed(ivu) }}</div>
                    </div>
                    <div class="values col-md-12" style="margin-top: -10px;">
                      <div style="font-size:12px;">
                        {{
                          languaje == "es"
                            ? "Ganancia de Tienda:"
                            : "Store Gain:"
                        }}
                      </div>
                      <div style="font-size:12px;">
                        ${{ storeGain ? setFixed(storeGain) : 0.0 }}
                      </div>
                    </div>
                    <div
                      class="values col-md-12"
                      v-if="deliveryFee > 1"
                      style="margin-top: -10px;"
                    >
                      <div style="font-size:12px;">
                        {{ $t("orders.deliveryFee") }}
                        {{
                          languaje == "es"
                            ? "Cargo por DElivery:"
                            : "Delivery Fee:"
                        }}
                      </div>
                      <div style="font-size:12px;">${{ deliveryFee }}.00</div>
                    </div>
                    <div class="values col-md-12" style="margin-top: -10px;">
                      <div style="font-size:12px;">Total:</div>
                      <div style="font-size:12px;">
                        ${{ total ? setFixed(total) : 0.0 }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="padding:0 36px">
                <div
                  @click="selectData(false)"
                  v-bind:class="{ datasel: !dataSel }"
                  class="btn-data"
                >
                  {{ languaje == "es" ? "NOTAS:" : "NOTES:" }}
                  <textarea
                    class="form-control mt-3 mb-3"
                    v-model="notes"
                    v-if="!dataSel && estado == 'IP'"
                    :placeholder="$t('orders.notNotes')"
                    cols="100"
                    rows="3"
                  ></textarea>
                </div>
                <div
                  @click="selectData(true)"
                  v-bind:class="{ datasel: dataSel }"
                  class="btn-data"
                >
                  {{ $t("orders.shipping") }}
                  <div v-if="dataSel" class="form-group m-4">
                    <label v-if="estado != 'C'">
                      {{ $t("orders.bagsQty") }}:
                      <a v-if="estado == 'A' || estado == 'F'">{{
                        bags
                      }}</a></label
                    >
                    <div class="row" v-if="estado == 'IP'">
                      <div class="col-1">
                        <button @click="minusBag()" class="btn btn-danger">
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                      <div class="col-5  ml-4">
                        <input
                          type="number"
                          class="form-control"
                          v-model="bags"
                        />
                      </div>
                      <div class="col-1">
                        <button @click="plusBag()" class="btn btn-success">
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="height:100px" class="row">
                <div v-if="estado != 'IP' && !dataSel">
                  <p class="note" v-if="notes != ''">{{ notes }}</p>
                  <p class="note" v-else>{{ $t("orders.notNotes") }}:</p>
                </div>
              </div>
              <div class="container">
                <div style="float:right">
                  <button
                    type="button"
                    @click="FinalizarOrden(item)"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    v-if="estado == 'IP' && storeGain != 0"
                    style="font-family: 'Montserrat', sans-serif; font-size: 15px; font-weight: bold; height: 34px; background: rgb(4, 167, 4); border: 0;"
                  >
                    {{ $t("orders.completeOrder") }}
                  </button>
                  <button
                    type="button"
                    @click="CancelarOrden(item)"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    v-if="estado == 'IP'"
                    style="font-family: 'Montserrat', sans-serif; font-size: 15px; font-weight: bold; height: 34px; background: rgba(235, 8, 8); border: 0;"
                  >
                    {{ $t("orders.cancelOrden") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    v-if="estado == 'A' && deliveryFee == 0"
                    @click="CompleteOrden(item)"
                    style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; font-weight: bold; background: #1e1e1e; border: 0;"
                  >
                    {{ $t("orders.deliverOrder") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Header from "../../components/Header.vue";
import VueBarcode from "vue-barcode";
import Parse from "parse";
import axios from "axios";
import print from "vue-print-nb";
export default {
  name: "Orders",
  components: {
    NavBar,
    barcode: VueBarcode,
    Header
  },
  directives: {
    print
  },
  data() {
    return {
      pickUp: {},
      dropOff: {},
      orders: [],
      bags: 1,
      item: null,
      usuarioFarmacia: {},
      // data
      estados: [],
      ordenSent: {},
      statusFilter: "Nueva Solicitud",
      estado: null,
      orden: null,
      dataSel: true,
      address: "",
      city: "",
      card: "",
      orderID: "",
      name: "",
      username: "",
      notificationToken: "",
      phone: "",
      status: "",
      notes: "",
      referenceNumber: "",
      customerID: "",
      subtotal: 0,
      total: 0,
      ivu: 0,
      storeGain: 0,
      picture: null,
      pictureUrl: null,
      data: null,
      orderns: null,
      // notes: "",
      storeId: null,
      audio: null,
      dataToSearch: "",
      superAdmin: null,
      admin: null,
      deliveryFee: 0,
      storeInfo: {},
      drivers: [],
      farmacia: {},
      languaje: "",
      customerLanguaje: "",
      originalTotal: 0,
      transactionKey: "",
      stripeUserId: "",
      driverSelected: "",
      driverName: ""
    };
  },
  mounted() {
    this.languaje = navigator.language || navigator.userLanguage;
    this.languaje = this.languaje.substr(0, 2);
    if (this.languaje === "es") {
      this.estados = [
        { id: "R", descripcion: "Nueva Solicitud" },
        { id: "IP", descripcion: "En Proceso" },
        { id: "A", descripcion: "Completado para Entrega" },
        { id: "E", descripcion: "Enviado con el delivery" },
        { id: "F", descripcion: "Entregada" },
        { id: "C", descripcion: "Cancelada" }
      ];
    } else {
      this.estados = [
        { id: "R", descripcion: "New Request" },
        { id: "IP", descripcion: "On proccess" },
        { id: "A", descripcion: "Completed for Delivery" },
        { id: "E", descripcion: "On The Way" },
        { id: "F", descripcion: "Delivered" },
        { id: "C", descripcion: "Canceled" }
      ];
    }
    // this.superAdmin = Parse.User.current().get("superAdmin");
    // this.admin = Parse.User.current().get("isAdmin");
    // if (this.admin && !this.superAdmin) {
    //   // this.$router.push("/userTabs");
    // }

    // if (!this.admin && !this.superAdmin) {
    //   this.$router.push("/");
    // }

    // if (!this.admin && this.superAdmin) {
    //   this.$router.push("/adminStores");
    // }
    this.seeUserStore();
    this.audio = new Audio(
      "http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3"
    );
    this.getStoreId();
    if (Parse.User.current() === null) {
      this.$router.push("/HelloWorld");
    }
    this.gettingOrders();
    this.farmacia = JSON.parse(localStorage.getItem("store"));
    this.usuarioFarmacia = JSON.parse(localStorage.getItem("usuarioFarmacia"));
    if (localStorage.getItem("stripeToken")) {
      if (localStorage.getItem("stripeToken").substr(0, 2) == "ac") {
        this.stripeUserId = localStorage.getItem("stripeToken");
        Parse.User.current().set("stripeAccountId", this.stripeUserId);
        Parse.User.current()
          .save()
          .then(() => {
            localStorage.removeItem("stripeToken");
          });
      }
    }
    this.getDriverStores();
  },
  methods: {
    printOrder() {},
    getOrder() {
      let query = new Parse.Query("ServiceRequest");
      query.startsWith("objectId", this.dataToSearch);
      query
        .find()
        .then(result => {
          this.orders = [];
          if (result) {
            this.orders = result;
          }
        })
        .catch(err => {
          this.$swal({
            title: "Ah ocurrido un error",
            html: err,
            icon: "error"
          });
        });
    },
    minusBag() {
      if (this.bags > 1) {
        this.bags--;
      } else {
        this.bags = 1;
      }
    },
    plusBag() {
      this.bags++;
    },
    seeUserStore() {
      if (JSON.parse(localStorage.getItem("usuarioFarmacia"))) {
        this.usuarioFarmacia = JSON.parse(
          localStorage.getItem("usuarioFarmacia")
        );
      }
    },
    selectData(data) {
      this.dataSel = data;
    },
    proccessArticle(articulos) {
      // esto para hacer el descuento de inventario y en caso de no existir no permitir
      articulos.forEach(articulo => {
        if (articulo.estado === true) {
          var query = new Parse.Query("Products");
          query.include("objectId");
          query.equalTo("objectId", articulo.prodID);
          query.first().then(producto => {
            if (
              parseInt(producto.get("quantity")) < articulo.quantityNumber &&
              producto.get("outOfStock") === true
            ) {
              localStorage.setItem("existe", "0");
            } else {
              localStorage.setItem("existe", "1");
            }
          });
        }
      });
    },
    discountArticle(articulos) {
      articulos.forEach(articulo => {
        if (articulo.estado === true) {
          // esto para hacer el descuento de inventario y en caso de no existir no permitir
          var query = new Parse.Query("Products");
          query.include("objectId");
          query.equalTo("objectId", articulo.prodID);
          query.first().then(producto => {
            let total =
              parseInt(producto.get("quantity")) - articulo.quantityNumber;
            producto.set("quantity", total < 1 ? "0" : total.toString());
            producto.set("outOfStock", total < 1 ? true : false);
            producto.save().then(() => {
              localStorage.removeItem("existe");
            });
          });
        }
      });
    },
    sendFunds(transaction) {
      let url =
        "https://us-central1-coopharma-c02de.cloudfunctions.net/transfer";
      // recuerda subir el proyectos de coopharma function
      // let url = "http://localhost:5001/coopharma-c02de/us-central1/transfer";
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(transaction)
      };
      fetch(url, options).catch(err => {
        this.$swal({
          title: "Ha ocurrido un error",
          icon: "error",
          html: err
        });
      });
    },
    sendRefunds(transaction) {
      let url = "https://us-central1-coopharma-c02de.cloudfunctions.net/Refund";
      // let url = "http://localhost:5001/coopharma-c02de/us-central1/Refund";
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(transaction)
      };
      fetch(url, options)
        .then(() => {})
        .catch(err => {
          this.$swal({
            title: this.languaje == "es" ? "Atencion!" : "Attention!",
            text:
              this.languaje == "es"
                ? "Algo ha pasado al enviar fondos, valide"
                : "Something has happent at sending funds, validate",
            icon: "error",
            html: err
          });
        });
    },
    FinalizarOrden(order) {
      let txt;
      if (this.storeGain === undefined || this.storeGain === 0) {
        this.$swal({
          title: this.languaje == "es" ? "Atencion!" : "Attention!",
          text:
            this.languaje == "es"
              ? "Debes seleccionar almenos un articulo de la orden"
              : "you have to select at least an article of the order",
          type: "info"
        });
      } else {
        if (
          confirm(
            this.languaje === "es"
              ? "¿Estás seguro que quieres aceptar esta orden?"
              : "Are you sure would you like to accept this order?"
          )
        ) {
          txt = "You pressed OK!";
          if (order) {
            this.finishOrder(order);
          } else {
            this.finishOrder(this.orden);
          }
        } else {
          txt = "You pressed Cancel!";
        }
        console.log(txt);
      }
    },
    CancelarOrden(order) {
      let txt;
      if (
        confirm(
          this.languaje === "es"
            ? "¿Estás seguro que quieres cancelar esta orden?"
            : "Are you sure would you like to cancel this order?"
        )
      ) {
        txt = "You pressed OK!";
        if (order) {
          this.cancelOrder(order);
        } else {
          this.cancelOrder(this.orden);
        }
      } else {
        txt = "You pressed Cancel!";
      }
      console.log(txt);
    },
    CompleteOrden(order) {
      let txt;
      if (
        confirm(
          this.languaje === "es"
            ? "¿Estás seguro que quieres completar esta orden?"
            : "Are you sure would you like to deliver this order?"
        )
      ) {
        txt = "You pressed OK!";
        if (order) {
          this.completarOrden(order);
        } else {
          this.completarOrden(this.orden);
        }
      } else {
        txt = "You pressed Cancel!";
      }
      console.log(txt);
    },
    cancelOrder(order) {
      order.set("status", "C");
      this.ordenSent = {
        orderID: this.orderID,
        status: "C",
        items: this.data,
        storeGain: this.storeGain,
        price: this.storeGain,
        orderTotal: this.total,
        subtotal: this.subtotal,
        startingSubtotal: this.subtotal,
        taxes: this.ivu,
        notes: this.notes,
        bags: this.bags,
        pickUp: this.pickUp,
        dropOff: this.dropOff,
        date: new Date(),
        customer: {
          id: this.customerID,
          name: this.name,
          username: this.username,
          phone: this.phone,
          notificationToken: this.notificationToken,
          address: this.address,
          languaje: this.customerLanguaje
        }
      };
      order
        .save()
        .then(() => {
          this.sendRefunds({
            amount: order.get("orderTotal") - 1,
            charge: order.get("stripeChargeId")
          });
          this.sendCustomerNofification();
        })
        .catch(error => {
          this.$swal({
            title: "Ha ocurrido un error",
            icon: "error",
            html: error
          });
        });
      this.gettingOrders();
    },
    mostrarboletin() {
      this.$swal({
        title: "AVISO",
        text: "No es posible en estos momentos, intente mas tarde",
        type: "info"
      });
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    finishOrder(order) {
      this.getDriverStores();
      let lugar = this.getRandomInt(0, this.drivers.length);
      this.ordenSent = {
        orderID: this.orderID,
        status: "A",
        items: this.data,
        storeGain: this.storeGain,
        price: this.storeGain,
        orderTotal: this.total,
        subtotal: this.subtotal,
        startingSubtotal: this.subtotal,
        taxes: this.ivu,
        notes: this.notes != "" ? this.notes : "No hay Notas",
        bags: this.bags,
        pickUp: this.pickUp,
        dropOff: this.dropOff,
        date: new Date(),
        customer: {
          id: this.customerID,
          name: this.name,
          username: this.username,
          phone: this.phone,
          notificationToken: this.notificationToken,
          address: this.address,
          languaje: this.customerLanguaje
        }
      };
      if (
        this.farmacia.addressPharmacy === "" ||
        this.farmacia.latitude === 0 ||
        this.farmacia.longitude === 0
      ) {
        this.$swal({
          title: this.languaje == "es" ? "Atencion!" : "Attention!",
          text:
            this.languaje == "es"
              ? "En Perfil debe suministrar los datos de localizacion de su farmacia"
              : "In Profile you have to supply the pharmacy data localization",
          type: "info"
        });
      } else {
        if (this.usuarioFarmacia.accountId) {
          if (
            this.drivers.length < 1 &&
            this.driverSelected !== "coopharma" &&
            this.deliveryFee > 1
          ) {
            this.$swal({
              title: this.languaje == "es" ? "Atencion!" : "Attention!",
              text:
                this.languaje == "es"
                  ? "No cuenta con drivers disponibles"
                  : "There are not able drivers",
              type: "info"
            });
          } else {
            this.proccessArticle(this.data);
            if (localStorage.getItem("existe") === "0") {
              this.$swal({
                title: this.languaje == "es" ? "Atención!" : "Atention!",
                text:
                  this.languaje == "es"
                    ? "No cuenta con productos suficiente disponibles para procesar un articulo de esta orden"
                    : "You don't have enough available products in order to proccess an article of this order",
                type: "info"
              });
            } else {
              this.discountArticle(this.data);
              order.set("status", "A");
              order.set("items", this.data);
              order.set("storeGain", this.storeGain);
              order.set("price", this.storeGain);
              order.set("orderTotal", this.storeGain);
              order.set("subtotal", this.subtotal);
              order.set("startingSubtotal", this.subtotal);
              order.set("taxes", this.ivu);
              order.set("notes", this.notes);
              order.set("bags", parseInt(this.bags));
              if (this.driverSelected === "own") {
                order.set(
                  "currentDriver",
                  Parse.Object.extend("_User")
                    .createWithoutData(this.drivers[lugar].get("driver").id)
                    .toPointer()
                );
              }
              this.ordenSent = {
                orderID: this.orderID,
                status: "A",
                items: this.data,
                storeGain: this.storeGain,
                price: this.storeGain,
                orderTotal: this.total,
                subtotal: this.subtotal,
                startingSubtotal: this.subtotal,
                taxes: this.ivu,
                notes: this.notes != "" ? this.notes : "No hay Notas",
                bags: this.bags,
                pickUp: this.pickUp,
                dropOff: this.dropOff,
                date: new Date(),
                customer: {
                  id: this.customerID,
                  name: this.name,
                  username: this.username,
                  phone: this.phone,
                  notificationToken: this.notificationToken,
                  address: this.address,
                  languaje: this.customerLanguaje
                }
              };
              order
                .save()
                .then(() => {
                  if (this.originalTotal === this.total) {
                    this.sendFunds({
                      amount:
                        this.deliveryFee > 1 ? this.total - 6 : this.total,
                      destination: JSON.parse(
                        localStorage.getItem("usuarioFarmacia")
                      ).accountId,
                      source: this.transactionKey
                    }).catch(() => {
                      this.$swal({
                        title:
                          this.languaje == "es" ? "Atencion!" : "Attention!",
                        text:
                          this.languaje == "es"
                            ? "Algo ha pasado al enviar fondos, valide"
                            : "Something has happent at sending funds, validate",
                        type: "info"
                      });
                    });
                    if (this.deliveryFee > 1) {
                      if (this.driverSelected === "coopharma") {
                        //envio a flexio
                        this.sendFunds({
                          amount: 5,
                          destination: "acct_1HFM68D1pFkKbXbS",
                          source: this.transactionKey
                        }).catch(() => {
                          this.$swal({
                            title:
                              this.languaje == "es"
                                ? "Atencion!"
                                : "Attention!",
                            text:
                              this.languaje == "es"
                                ? "Algo ha pasado al enviar fondos, valide"
                                : "Something has happent at sending funds, validate",
                            type: "info"
                          });
                        });
                      } else {
                        //envio a local driver

                        this.sendFunds({
                          amount: 4,
                          destination: JSON.parse(
                            localStorage.getItem("usuarioFarmacia")
                          ).accountId,
                          source: this.transactionKey
                        }).catch(() => {
                          this.$swal({
                            title:
                              this.languaje == "es"
                                ? "Atencion!"
                                : "Attention!",
                            text:
                              this.languaje == "es"
                                ? "Algo ha pasado al enviar fondos, valide"
                                : "Something has happent at sending funds, validate",
                            type: "info"
                          });
                        });
                      }
                    }
                  } else {
                    //envio a farmacia
                    this.sendFunds({
                      amount:
                        this.deliveryFee > 1 ? this.total - 6 : this.total,
                      destination: JSON.parse(
                        localStorage.getItem("usuarioFarmacia")
                      ).accountId,
                      source: this.transactionKey
                    }).catch(() => {
                      this.$swal({
                        title:
                          this.languaje == "es" ? "Atencion!" : "Attention!",
                        text:
                          this.languaje == "es"
                            ? "Algo ha pasado al enviar fondos, valide"
                            : "Something has happent at sending funds, validate",
                        type: "info"
                      });
                    });
                    // envio de fondos restantes
                    this.sendRefunds({
                      amount: this.originalTotal - this.total,
                      charge: this.transactionKey
                    });
                    if (this.deliveryFee > 1) {
                      if (this.driverSelected === "coopharma") {
                        //envio a flexio
                        this.sendFunds({
                          amount: 5,
                          destination: "acct_1HFM68D1pFkKbXbS",
                          source: this.transactionKey
                        }).catch(() => {
                          this.$swal({
                            title:
                              this.languaje == "es"
                                ? "Atencion!"
                                : "Attention!",
                            text:
                              this.languaje == "es"
                                ? "Algo ha pasado al enviar fondos, valide"
                                : "Something has happent at sending funds, validate",
                            type: "info"
                          });
                        });
                      } else {
                        //envio a local driver

                        this.sendFunds({
                          amount: 4,
                          destination: JSON.parse(
                            localStorage.getItem("usuarioFarmacia")
                          ).accountId,
                          source: this.transactionKey
                        });
                      }
                    }
                  }
                  this.$swal({
                    title: this.languaje == "es" ? "Completado!" : "Completed!",
                    text:
                      this.languaje == "es"
                        ? "Orden lista para Entrega"
                        : "Order ready to deliver it",
                    type: "success"
                  });
                  // this.sendCustomerNofification();
                  if (this.deliveryFee > 1) {
                    this.sendDriverData(this.ordenSent);
                  }
                })
                .catch(error => {
                  this.$swal({
                    title: "Ha ocurrido un error",
                    icon: "error",
                    html: error
                  });
                });
              this.gettingOrders();
            }
          }
        } else {
          this.$swal({
            title: this.languaje == "es" ? "Atencion!" : "Attention!",
            text:
              this.languaje == "es"
                ? "En Perfil debe suministrar una cuenta de Stripe valida en su farmacia"
                : "In Profile you have to supply a valid pharmacy Stripe account",
            type: "info"
          });
        }
      }
    },
    completarOrden(order) {
      order.set("status", "F");
      order.set("dateComplete", new Date(Date.now()));
      order
        .save()
        .then(() => {
          this.$swal({
            title: this.languaje == "es" ? "¡Alerta!" : "Alert!",
            text:
              this.languaje == "es" ? "Orden Completada." : "Order Delivered",
            icon: "info"
          });
          this.sendCustomerNofification();
        })
        .catch(error => {
          this.$swal({
            title: "Ha ocurrido un error",
            icon: "error",
            html: error
          });
        });
      this.gettingOrders();
    },
    sendFakeDriverData() {
      // let articulos = [];
      // let tienda = null;
      // anterior
      // const url = "http://flexioapi.us-east-2.elasticbeanstalk.com/api/create-order";
      const url = "https://mallapp.api-flexiopr.com/api/create-order";
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          bags: 0,
          customer: {
            address: "Calle Cruz Del Sur, Carolina 00979",
            id: "5ytu65trhr",
            name: "Edian Reyes",
            phone: "7877452544",
            username: "ediboy@gmail.com"
          },
          date: "Mon Oct 08 2020 1:30:00 GMT-0400 (Atlantic Standard Time)",
          dropOff: {
            latitud: 18.444613,
            longitud: -66.016316
          },
          items: [
            {
              description: "my item test desc Edian",
              estado: true,
              id: 0,
              ivu: false,
              ivuPercent: 0,
              originalPrice: 10,
              prodID: "Y8zQeoVHgx",
              prodImage:
                "https://parsefiles.back4app.com/RAOCbllwESClztVns4k4u7RZ1kMPsVs0JHy0JdBb/d617cf5c93be1cc272b8365c9c427cd5_photo.jpeg",
              prodName: "ACIDPLEX CAP 90  NORMANS",
              prodPrice: 10,
              quantityNumber: 1,
              storeID: "ntWPYdVDP1"
            }
          ],
          notes: "Pixnabi test",
          orderID: "noorderid",
          orderTotal: 10,
          pickUp: {
            addressPharmacy: "Calle A, Carolina 00979",
            gpsPharmacy: {},
            latitude: 18.426868,
            longitude: -66.007331,
            idPharmacy: "rRObFleXio",
            namePharmacy: "Farmacia Prueba Edian"
          },
          price: 10,
          startingSubtotal: 10,
          status: "A",
          storeGain: 10,
          subtotal: 10,
          taxes: 0
        })
      };
      fetch(url, options)
        .then(() => {})
        .catch(err => {
          this.$swal({
            title: "Ha ocurrido un error",
            icon: "error",
            html: err
          });
        });
    },
    sendDriverData(orden) {
      let articulos = [];
      orden.items.forEach(item => {
        articulos.push({
          description:
            item.description === "" ? "sin descripcion" : item.description,
          estado: item.estado,
          id: item.id,
          ivu: item.ivu,
          ivuPercent: item.ivuPercent,
          originalPrice: item.originalPrice,
          prodID: item.prodID,
          prodImage: item.prodImage,
          prodName: item.prodName,
          prodPrice: item.prodPrice,
          quantityNumber: item.quantityNumber,
          storeID: item.storeID
        });
      });
      // let tienda = null;
      const url = "https://mallapp.api-flexiopr.com/api/create-order";
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          bags: orden.bags,
          customer: {
            address: orden.customer.address,
            id: orden.customer.id,
            name: orden.customer.name,
            phone: orden.customer.phone,
            username: orden.customer.username
          },
          date: orden.date,
          dropOff: {
            latitud: orden.dropOff.longitud,
            longitud: orden.dropOff.longitud
          },
          items: articulos,
          notes: orden.notes === "" ? "No hay notas" : orden.notes,
          orderID: orden.orderID,
          orderTotal: orden.orderTotal,
          pickUp: {
            addressPharmacy: this.farmacia.addressPharmacy,
            latitude: this.farmacia.latitude,
            longitude: this.farmacia.longitude,
            idPharmacy: this.farmacia.idPharmacy,
            namePharmacy: this.farmacia.namePharmacy,
            gpsPharmacy: {}
          },
          price: orden.price,
          startingSubtotal: orden.startingSubtotal,
          status: orden.status,
          storeGain: orden.storeGain,
          subtotal: orden.subtotal,
          taxes: orden.taxes
        })
      };

      fetch(url, options).catch(err => {
        this.$swal({
          title: "Ha ocurrido un error",
          icon: "error",
          html: err
        });
      });
    },
    sendCustomerNofification() {
      let action = "";
      let title = "";
      let body = "";
      switch (this.ordenSent.status) {
        case "A":
          action = "completed";
          title =
            this.ordenSent.customer.languaje == "es"
              ? "Orden Completada para Delivery"
              : "Order Completed For Delivery";
          body =
            this.ordenSent.customer.languaje == "es"
              ? "Tu Orden " +
                this.ordenSent.orderID +
                " ha sido completada para Delivery"
              : "Your Order " +
                this.ordenSent.orderID +
                " has been Completed For Delivery";
          break;
        case "IP":
          action = "onProcess";
          title =
            this.ordenSent.customer.languaje == "es"
              ? "Orden en Proceso"
              : "Order on Proccess ";
          body =
            this.ordenSent.customer.languaje == "es"
              ? "Tu Orden " + this.ordenSent.orderID + " esta en Proceso"
              : "Your Order " + this.ordenSent.orderID + " is On Proccess";
          break;
        case "F":
          action = "delivered";
          title =
            this.ordenSent.customer.languaje == "es"
              ? "Orden Entregada"
              : "Order Delivered";
          body =
            this.ordenSent.customer.languaje == "es"
              ? "Tu Orden " + this.ordenSent.orderID + " ha sido entregada"
              : "Your Order " + this.ordenSent.orderID + " has been Delivered";
          break;
        case "C":
          action = "canceled";
          title =
            this.ordenSent.customer.languaje == "es"
              ? "Orden Cancelada"
              : "Order Canceled";
          body =
            this.ordenSent.customer.languaje == "es"
              ? "Tu Orden " + this.ordenSent.orderID + " ha sido Cancelada"
              : "Your Order " + this.ordenSent.orderID + " has been Canceled";
          break;
      }
      axios.post(
        "https://us-central1-coopharma-c02de.cloudfunctions.net/app/sendNotification",
        {
          action: action,
          title: title,
          orderId: this.ordenSent.orderID,
          body: body,
          clientToken: this.ordenSent.customer.notificationToken
        }
      );
    },
    async getStoreId() {
      await Parse.Cloud.run("getStore", {
        //get the user store
        userId: Parse.User.current().id
      }).then(result => {
        localStorage.setItem(
          "store",
          JSON.stringify({
            addressPharmacy: result.attributes.Address,
            gpsPharmacy: {
              latitude: result.attributes.gps._latitude,
              longitude: result.attributes.gps._longitude
            },
            latitude: result.attributes.gps._latitude,
            longitude: result.attributes.gps._longitude,
            idPharmacy: result.id,
            namePharmacy: result.attributes.Name
          })
        );
        // error encontrado en drivers
        this.driverSelected = result.attributes.definedDelivery;
        this.pictureUrl = result.attributes.storePicture._url;
        this.storeId = result.id;
        var store = Parse.Object.extend("Store")
          .createWithoutData(this.storeId)
          .toPointer();
        //  Live Query
        var client = new Parse.LiveQueryClient({
          applicationId: "RAOCbllwESClztVns4k4u7RZ1kMPsVs0JHy0JdBb",
          serverURL: "wss://coopharma.back4app.io", // Example: 'wss://livequerytutorial.back4app.io'
          javascriptKey: "5RZGuP5oS761Q5VM655LeKJ4eszjkoSrbMFOdPwW",
          masterKey: "4ZCJxnFPx9nTK4SWDwBq4imO8MOj8e01L9KoDyyO"
        });

        client.open();
        var query = new Parse.Query("ServiceRequest");
        query.descending("createdAt");
        query.containedIn("status", ["R", "C", "F", "E"]);
        query.equalTo("store", store);
        var subscription = client.subscribe(query);

        subscription.on("create", todo => {
          this.gettingOrders();
          this.audio.play();

          this.$swal({
            title: this.languaje == "es" ? "¡Alerta!" : "Alert!",
            text:
              this.languaje == "es"
                ? "Nueva Orden " +
                  todo.id +
                  " de " +
                  todo.get("user").get("fullName")
                : "New Order " +
                  todo.id +
                  " of " +
                  todo.get("user").get("fullName"),
            type: "info"
          });
        });

        subscription.on("update", todo => {
          // this.gettingOrders();
          switch (todo.get("status")) {
            case "C": {
              this.$swal({
                title: this.languaje == "es" ? "¡Alerta!" : "Alert!",
                text:
                  this.languaje == "es"
                    ? "Orden " +
                      todo.id +
                      " Cancelada, de " +
                      todo.get("user").get("fullName")
                    : "Order " +
                      todo.id +
                      " Canceled, of " +
                      todo.get("user").get("fullName"),
                type: "info"
              });
              this.audio.play();
              break;
            }
            case "F": {
              this.$swal({
                title: this.languaje == "es" ? "¡Alerta!" : "Alert!",
                text:
                  this.languaje == "es"
                    ? "Orden " +
                      todo.id +
                      " Entregada, de " +
                      todo.get("user").get("fullName")
                    : "Order " +
                      todo.id +
                      " Delivered, of " +
                      todo.get("user").get("fullName"),
                type: "info"
              });
              this.audio.play();
              break;
            }
            case "E": {
              // this.$swal({
              //   title: this.languaje == "es"?"¡Alerta!":"Alert!",
              //   text: this.languaje == "es"?"Orden "+todo.id+" En Camino, de "+todo.get("user").get("fullName")+", sera entregada por "+todo.get("currentDriver").get("fullName"):"Order "+todo.id+" On The Way, of "+todo.get("user").get("fullName")+", will be delivered by "+todo.get("currentDriver").get("fullName"),
              //   type: "info",
              // });
              // this.audio.play();
              break;
            }
          }
        });

        // subscription.on("delete", (todo) => {
        //   this.gettingOrders();
        //   this.audio.play();

        //   this.$swal({
        //     title: "¡Alerta!",
        //     text: "Un servicio ha sido borrado.",
        //     type: "info",
        //   });
        // });
      });
    },

    gettingOrders() {
      this.orders = [];
      Parse.Cloud.run("getOrdersbyUser", {
        employeeId: Parse.User.current().id,
        status: this.statusFilter
      }).then(result => {
        this.orders = result;
      });
      return this.orders;
    },
    getDriverStores() {
      // let self = this;
      const store = new Parse.Query("Store");
      store.include("employee");
      store.equalTo(
        "employee",
        Parse.Object.extend("_User").createWithoutData(Parse.User.current().id)
      );
      store.first().then(store => {
        const query = new Parse.Query("storeDrivers");
        query.include("driver");
        query.include("store");
        query.include("stripeAccountId");
        query.equalTo("vinculated", true);
        query.equalTo("working", false);
        query.equalTo("active", true);
        query.equalTo(
          "store",
          Parse.Object.extend("Store").createWithoutData(store.id)
        );
        query.find().then(drivers => {
          drivers.forEach(driver => {
            this.drivers.push(driver);
          });
        });
      });
    },
    seeOrder(order) {
      this.originalTotal =
        order.get("subtotal") + order.get("taxes") + order.get("deliveryFee");
      this.data = [];
      this.orderns = [];
      if (order.get("status") === "R") {
        order.set("status", "IP");
        order.save();
      }
      this.dropOff = {
        longitud: order.get("longitud"),
        latitud: order.get("latitud")
      };
      this.deliveryFee = order.get("deliveryFee");
      if (order.get("currentDriver")) {
        this.driverName = order.get("currentDriver").get("fullName");
      } else {
        this.driverName = "";
      }
      this.orden = order;
      this.estado = order.get("status");
      this.orderID = order.id;
      this.transactionKey = order.get("stripeChargeId");
      this.name = order.get("user").get("fullName");
      this.username = order.get("user").get("username");
      this.phone = order.get("user").get("phone");
      this.notificationToken = order.get("user").get("NotificationToken")
        ? order.get("user").get("NotificationToken")
        : "no token";
      this.customerLanguaje = order.get("user").get("DefaultLanguaje")
        ? order.get("user").get("DefaultLanguaje")
        : "es";
      const query = new Parse.Query("Address");
      query.include("objectId");
      query.equalTo("objectId", order.get("addressId"));
      query.first().then(adrs => {
        if (adrs !== undefined) {
          this.address = adrs.get("Address");
        }
      });
      this.customerID = order.get("user").id;
      this.status = order.get("status");
      this.notes = order.get("notes");
      this.bags = order.get("bags");
      this.ProccessPayment(this.data);
      this.subtotal = order.get("subtotal");
      this.ivu = order.get("taxes");
      // let total = this.subtotal * order.get("store").get("percent");
      this.storeGain = order.get("storeGain");
      this.total = this.storeGain + this.ivu;
      this.data = order.get("items");
      console.log(this.data);
      this.ordenSent = {
        orderID: this.orderID,
        status: "IP",
        items: this.data,
        storeGain: this.storeGain,
        price: this.storeGain,
        orderTotal: this.total,
        subtotal: this.subtotal,
        startingSubtotal: this.subtotal,
        taxes: this.ivu,
        notes: this.notes,
        bags: this.bags,
        pickUp: this.farmacia,
        dropOff: this.dropOff,
        date: new Date(),
        customer: {
          id: this.customerID,
          name: this.name,
          username: this.username,
          phone: this.phone,
          notificationToken: this.notificationToken,
          address: this.address,
          languaje: this.customerLanguaje
        }
      };
      this.sendCustomerNofification();
      let key = 0;
      let articulo = {};
      this.orderns = [];
      for (let orden of this.data) {
        articulo = {
          id: key,
          description: orden.description,
          ivu: orden.ivu,
          ivuPercent: orden.ivuPercent,
          originalPrice: parseFloat(orden.originalPrice),
          prodID: orden.prodID,
          prodName: orden.prodName,
          prodImage: orden.prodImage,
          prodPrice: parseFloat(orden.prodPrice),
          quantityNumber: orden.quantityNumber,
          storeID: orden.storeID,
          upc: orden.upc,
          estado: orden.estado ? true : false
        };
        this.orderns.push(articulo);
        key++;
      }
      this.data = [];
      this.data = this.orderns;
      if (order.get("bags") != null) {
        this.bags = order.get("bags");
      } else {
        this.bags = 1;
      }
      if (order.get("notes") != null) {
        this.notes = order.get("notes");
      } else {
        this.notes = "";
      }
    },
    setFixed(total) {
      return parseFloat(total).toFixed(2);
    },
    deleteItem(item) {
      item.destroy().then(() => {
        this.gettingOrders();
      });
    },
    ProccessPayment() {
      this.subtotal = 0;
      this.ivu = 0;
      // let ivuSum = 0;
      let proccessOrders = [];
      let key = 0;
      for (let order of this.data) {
        if (document.getElementById(key).checked) {
          this.subtotal += order.originalPrice * order.quantityNumber;
          proccessOrders.push({
            id: order.id,
            description:
              order.description != "" ? order.description : "Sin descripcion",
            ivu: order.ivu,
            ivuPercent: order.ivuPercent,
            originalPrice: order.originalPrice,
            prodID: order.prodID,
            prodName: order.prodName,
            prodImage: order.prodImage,
            prodPrice: order.prodPrice,
            quantityNumber: order.quantityNumber,
            storeID: order.storeID,
            estado: true
          });
          if (order.ivu) {
            this.ivu +=
              order.ivuPercent * (order.originalPrice * order.quantityNumber);
          }
        } else {
          proccessOrders.push({
            id: order.id,
            description: order.description,
            ivu: order.ivu,
            ivuPercent: order.ivuPercent,
            originalPrice: order.originalPrice,
            prodID: order.prodID,
            prodName: order.prodName,
            prodImage: order.prodImage,
            prodPrice: order.prodPrice,
            quantityNumber: order.quantityNumber,
            storeID: order.storeID,
            estado: false
          });
        }
        key++;
      }
      this.data = [];
      this.data = proccessOrders;
      console.log(proccessOrders);
      this.storeGain = this.ivu + this.subtotal;
      this.total = this.storeGain + this.deliveryFee;
    }
  },
  watch: {
    statusFilter() {
      this.gettingOrders();
    }
  }
};
</script>
<style scoped>
.bs {
  width: 40px;
  height: 40px;
  size: 80px;
  margin-right: -25px;
}
.note {
  padding: 0 35px;
  text-align: justify;
  margin-top: 10px;
}

.values {
  display: flex;
  margin: 10px 0px;
  justify-content: space-between;
  size: 12px;
}

.datasel {
  border-bottom: 2px solid black !important;
}

.btn-data {
  width: 50%;
  text-align: center;
  cursor: pointer;
  height: 30px;
  transition: 0.5s all ease;
  border-bottom: 2px solid #b7b7b7;
}
.modal-position {
  position: absolute !important;
  right: 0 !important;
  margin: 0 !important;
}

.modal-dialog {
  width: 500px;
}

.modal-header {
  padding: 1rem 1rem 0 1rem !important;
}

.modal-body {
  padding: 0 1rem !important;
}

.modal-content {
  height: 100vh !important;
}

.modalContainer {
  background-color: #e0e0e0;
}
.modalItemList {
  width: 100%;
  height: 175px;
  overflow-y: auto;
  padding-bottom: 20px;
  /* background: #fff;
    box-shadow: 0 2.5px 13.5px 0 rgba(10, 10, 10, 0.15); */
  overflow-x: hidden;
  overflow-y: auto;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
.textDescription {
  font-size: 13px;
  text-align: left;
  margin-top: 0px;
  margin-left: -15px;
  /* margin-top: 10px; */
}
.textDescription2 {
  font-size: 13px;
  margin-top: 10px;
  margin-left: 4%;
}
.check {
  size: 20px;
  padding-bottom: 30px;
  vertical-align: middle;
}
.descriptionText {
  font-size: 12px;
  color: rgb(61, 61, 61);
  margin-top: 10px;
  margin-left: 4%;
}

.row.odd {
  background: #ececec;
}
.textOrder {
  font-size: 12px;
  margin-top: 10px;
  margin-left: 5%;
  font-weight: 800;
  margin-top: 2vh;
  margin-bottom: 35px;
}
.detailsText3 {
  font-size: 10px;
  margin-left: 5%;
  font-weight: 800;
  color: #9e9e9e;
}
.detailsText4 {
  /* margin-top: -18px; 
    /* font-size: 0.8vw; */
  margin-left: 5%;
  font-weight: 800;
  color: #9e9e9e;
  /* margin-bottom: 13px;*/
}
.detailsText5 {
  margin: 0;
  font-size: 0.8vw;
  margin-left: 5%;
  margin-top: 0vh;
  color: #9e9e9e;
}

.statusLabel {
  margin-top: -10px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  border-radius: 5px;
  text-align: center;
  font-size: 0.8vw;
  margin-left: 10%;
  font-weight: 800;
  margin-bottom: 15px;
  color: #fff;
  margin: 15px;
}
.textOrder2 {
  font-size: 15px;
  margin-top: 1px;
  margin-left: 5%;
  font-weight: 800;
}
.totalCard {
  width: 90%;
  margin: auto;
  display: block;
  overflow-y: hidden;
  overflow-x: hidden;
  height: auto;
  max-height: 450px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 7px 20px 2px rgba(0, 0, 0, 0.05),
    0px 4px 18px rgba(0, 0, 0, 0.05);
}

.specialBody {
  height: 400px;
}
.productPicture {
  width: 35%;
  float: left;
  display: flex;
  justify-content: center;
}
.productDescription {
  width: 65%;
  float: right;
}
.productImg {
  width: 100%;
  height: 80px;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  margin: -10px;
}
.suprimirImg {
  width: 100%;
  height: 80px;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  margin: -10px;
  filter: opacity(20%);
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
.label {
  font-size: 30px;
  color: #a19c9c;
  font-weight: 500;
  align-content: center;
  margin-left: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}
.newRequestState {
  background: #5692bd;
  color: #fff;
}
.deliveryState {
  background: #2e6600;
  color: #fff;
}
.pickupState {
  background: #bb6a00;
  color: #fff;
}

.acceptedState {
  background: #7773c7;
  color: #fff;
  width: 180px;
}

.suprimido {
  color: rgb(175, 173, 173);
  font-size: 12px;
  text-decoration: line-through;
  margin-top: 10px;
  margin-left: 4%;
}

.onWayState {
  background: #008d7a;
  color: rgb(255, 255, 255);
}

.onProccessState {
  background: #f3ec11;
  color: #000;
}

.deliveredState {
  background: #56bd9a;
  color: #fff;
}

.canceledState {
  background: #f31111;
  color: #fff;
}
</style>
